








































import Vue from 'vue';
import {mapGetters, mapMutations, mapActions} from 'vuex'
import Tablet from '@/components/Tablet.vue'

export default Vue.extend({
  name: 'MessyDesk',
  components: {
    Tablet
  },
  props: {
    startActive: {
      type: Boolean,
      default: false
    },
    preventHide: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      lines: [],
      hintsUsed: 0,
      cleanup: {
        cabinetkey: {
          problem: "Key taped to file cabinet.",
          solution: "Store key in secure location.",
          alternative: "Try to figure out a way to lock the key in the cabinet (remember, there's only one key)."
        },
        cabinet: {
          problem: "File cabinet left unlocked and unattended.",
          solution: "Lock cabinet while not in use.",
          alternative: "Shut and lock the cabinet, but keep the paper halfway hanging out so you can look at the information you need when you need to."
        },
        briefcase: {
          problem: "Open brief case with confidential papers inside.",
          solution: "Secure files and close briefcase.",
          alternative: "Move the laptop back a little further and behind the trash can."
        },
        trash: {
          problem: "Trash bin contains confidential papers.",
          solution: "Put in secure shred bin.",
          alternative: "Take the trash and dump it all into the break room trash can."
        },
        cd: {
          problem: "CD left in computer.",
          solution: "Store in locked file cabinet.",
          alternative: "Take the CD out and put it on your desk"
        },
        shredbox: {
          problem: "Shred box contains easily accessible confidential papers.",
          solution: "Take papers to secure shred box.",
          alternative: "Put the documents into the trash can."
        },
        binders: {
          problem: "Binders with sensitive information.",
          solution: "Store in lockable binder cabinet.",
          alternative: "Flip the binders the other direction so the label doesn't show.  This way no one will know what's in them."
        },
        flashdrive: {
          problem: "Flash drive left on desk.",
          solution: "Don't leave the flash drive out in the open.",
          alternative: "Plug the flash drive into the computer and leave it there."
        },
        keys: {
          problem: "Keys left on desk.",
          solution: "Keys should be stored out of plain sight or should remain in your possession.",
          alternative: "Try to put one key in the cabinet lock and leave them dangling there"
        },
        accesscard: {
          problem: "Access card left on desk.",
          solution: "Key cards should always remain in your possession or be stored in a secured area.",
          alternative: "Hide the key card in between the binders."
        },
        smartphone: {
          problem: "Smart phone left on desk.",
          solution: "Phone should remain in your possession or be stored in a secure area.",
          alternative: "Turn the phone face down"
        },
        mail: {
          problem: "Sensitive mail left on desk.",
          solution: "Stow in lockable cabinet.",
          alternative: "Take the mail out of the box and leave it all on the table.  This way you can recycle the box."
        },
        stickypin: {
          problem: "Sticky note with PIN code on phone.",
          solution: "Memorize or store in your password manager.",
          alternative: "Tell your colleague or friend your PIN, so if you forget, they can remind you."
        },
        computer: {
          problem: "Computer left unlocked and logged on.",
          solution: "Activate lock screen, or log off.",
          alternative: "It's ok to leave your computer unlocked, just don't keep your calendar or email up at the same time."
        },
        whiteboard: {
          problem: "White board with sensitive information.",
          solution: "Make sure to wipe information off whiteboards so others that come later can't see that information.",
          alternative: "Take the board off the wall and leave it in the hallway."
        },
        stickypass: {
          problem: "Sticky note with password left on monitor.",
          solution: "Memorize the password or use your password manager.",
          alternative: "Take the sticky note and put it under your keyboard"
        }
      },
      clickedItem: null,
      currentCleanup: {problem: "...", solution: "...", alternative: "..."},
      flipOptions: false,
      showPopup: false,
      actionsTaken: [],
      totalClicks: 0,
      hintAvailable: true,
      showTargets: false,
      minActions: 5,
      tookAlternative: false
    }
  },
  computed: {
    ...mapGetters([
      'stageAnswers',
      'stageHints',
      'hintsRemaining'
    ]),
    correctAnswer () {
      return (this.stageAnswers.hasOwnProperty(this.stage)) ? this.stageAnswers[this.stage] : 'What?'
    },
    hintsAvailable () {
      return (this.stageHints.hasOwnProperty(this.stage)) ? this.stageHints[this.stage] : []
    }
  },
  methods: {
    ...mapActions([
      'loseHint'
    ]),
    show () {
      console.log('MessyDesk.show')
      this.$refs.tablet.show()
    },
    hide () {
      this.$refs.tablet.hide()
    },
    missed () {
      this.totalClicks++
    },
    pop (deskItem) {
      this.flipOptions = (Math.random() < 0.5) ? false : true;
      this.clickedItem = deskItem
      if (this.actionsTaken.indexOf(this.clickedItem)!==-1) return;
      this.currentCleanup = this.cleanup[deskItem]
      this.showPopup = true
      this.totalClicks++
    },
    takeAction () {
      if (this.actionsTaken.indexOf(this.clickedItem)===-1) this.actionsTaken.push(this.clickedItem)
      this.showPopup = false
      this.tookAlternative = false
      if (this.actionsTaken.length >= this.minActions) {
        this.$emit('complete')
        this.hide()
      }
    },
    takeAltAction () {
      this.tookAlternative = true
      window.setTimeout(() => {
        this.tookAlternative = false
      }, 1000)
    },
    addLines (lines) {
      this.lines = [...this.lines, ...lines]
      this.$nextTick(()=>{
        this.$refs.console.scrollTop = 9999999
      })
    },
    submitPassword () {
      if (this.passwordEntry.trim().toUpperCase() === this.correctAnswer) {
        console.log('correct')
        this.$emit('correct')
        this.hide()
        return
      }
      console.log('incorrect')
      this.addLines([
        {text: this.passwordEntry, class:'echo'},
        {text: 'Incorrect. A 30 second time penalty has been applied.', class:''}
      ])
    },
    useHint () {
      this.showTargets = true
      this.hintAvailable = false
      this.loseHint()
    },
    requestAssistance () {

    },
    showCoordinates (event) {
      console.log('showCoordinates', event)
    }
  },
  mounted () {
    this.active = this.startActive
  }
});
