













import Vue from 'vue';
import {mapGetters, mapMutations, mapActions} from 'vuex'
import GameHud from '@/components/GameHud.vue'
import GameMap from '@/components/GameMap.vue'
import Fanfair from '@/components/Fanfair.vue'
import Curtain from '@/components/Curtain.vue'
import MessyDesk from '@/components/MessyDesk.vue'

export default Vue.extend({
  name: 'Bridge',
  components: {
    GameHud,
    GameMap,
    Fanfair,
    Curtain,
    MessyDesk
  },
  props: {
  },
  data () {
    return {
      curtainActive: true,
      curtainHeading: 'Bridge',
      exerciseWindow: null,
      showFanfair: false
    }
  },
  computed: {
    ...mapGetters([
      'team',
    ]),
    stageCompleted () {
      return (this.team.ready && this.team.completedBridge)
    },
  },
  methods: {
    ...mapActions([
      'timerStart',
      'timerStop',
      'completeStage'
    ]),
    handleHudClick (action: string) {
      if (typeof this[action] === 'function') {
        this[action]()
      }
    },
    showMap () {
      this.$refs.gameMap.show()
    },
    goto (loc: string, heading: string, delay: number) {
      console.log('Maproom.goto')
      this.curtainHeading = heading
      this.curtainActive = true
      window.setTimeout(()=>{
        this.$router.push(loc)
      }, delay)
      this.timerStop()
    },
    onCurtainClick () {
      this.curtainActive = false
    },
    startExercise () {
      this.$refs.messyDesk.show()
    },
    completeExercise () {
      this.completeStage('Bridge')
      this.timerStop()
    },
    conclude () {
      this.goto('/conclusion', 'Conclusion')
    }
  },
  mounted () {
    this.$store.dispatch('updateMyTeam', {lastLocation: 'Bridge'})
    window.setTimeout(()=>{
      this.curtainActive = false
      if (this.stageCompleted) this.$refs.fanfair.show()
      else this.timerStart('Bridge')
    }, 500)
  },
  watch: {
    stageCompleted () {
      if (this.stageCompleted) {
        this.$refs.fanfair.show()
        window.setTimeout(this.conclude, 5000)
      }
    }
  }
});
